var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import __NG_CLI_RESOURCE__0 from "./AppComponent.html";
import { Component } from '@angular/core';
import { CbrDateService } from '@client/CbrDate/CbrDateService';
import { ZoneAsync } from '@client/Common/ZoneAsync';
import { ToastsService } from '@client/Toasts/ToastsService';
import { CredentialService } from '../modules/Credentials/CredentialService';
import '@angular/localize/init';
import '../../misc/favicon.ico';
import './AppComponent.scss';
let AppComponent = class AppComponent {
    // @ViewChild('DatePicker')
    // public DatePicker: DatePickerComponent;
    constructor(Credentials, _toast, _cbr) {
        this.Credentials = Credentials;
        this._toast = _toast;
        this._cbr = _cbr;
        this.Version = ENV.VERSION;
        this.Rates = {};
        // (async () => {
        //   await delay(1000);
        // })();
    }
    async onDate(_dt) {
        const dt = new Date(_dt.year, _dt.month - 1, _dt.day);
        this._cbr.ValueChange.subscribe(_mfRate => {
            this.Rates = _mfRate;
        });
    }
};
AppComponent.ctorParameters = () => [
    { type: CredentialService },
    { type: ToastsService },
    { type: CbrDateService }
];
__decorate([
    ZoneAsync,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Promise)
], AppComponent.prototype, "onDate", null);
AppComponent = __decorate([
    Component({
        selector: 'app',
        template: __NG_CLI_RESOURCE__0,
    }),
    __metadata("design:paramtypes", [CredentialService,
        ToastsService,
        CbrDateService])
], AppComponent);
export { AppComponent };
