/**
 *
 */
/**
 *
 */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
const pPositionRoute = [
    { path: 'c', loadChildren: () => import('./modules/Console/Pages/Console/ConsolePageModule').then(_m => _m.ConsolePageModule) },
    { path: 'c/:0', loadChildren: () => import('./modules/Console/Pages/Console/ConsolePageModule').then(_m => _m.ConsolePageModule) },
    { path: 'c/:0/:1', loadChildren: () => import('./modules/Console/Pages/Console/ConsolePageModule').then(_m => _m.ConsolePageModule) },
    { path: 'c/:0/:1/:2', loadChildren: () => import('./modules/Console/Pages/Console/ConsolePageModule').then(_m => _m.ConsolePageModule) },
    { path: 'c/:0/:1/:2/:3', loadChildren: () => import('./modules/Console/Pages/Console/ConsolePageModule').then(_m => _m.ConsolePageModule) },
    { path: 'c/:0/:1/:2/:3/:4', loadChildren: () => import('./modules/Console/Pages/Console/ConsolePageModule').then(_m => _m.ConsolePageModule) },
    { path: 'c/:0/:1/:2/:3/:4/:5', loadChildren: () => import('./modules/Console/Pages/Console/ConsolePageModule').then(_m => _m.ConsolePageModule) },
    { path: 'c/:0/:1/:2/:3/:4/:5/:6', loadChildren: () => import('./modules/Console/Pages/Console/ConsolePageModule').then(_m => _m.ConsolePageModule) },
    { path: 'c/:0/:1/:2/:3/:4/:5/:6/:7', loadChildren: () => import('./modules/Console/Pages/Console/ConsolePageModule').then(_m => _m.ConsolePageModule) },
    { path: 'c/:0/:1/:2/:3/:4/:5/:6/:7/:8', loadChildren: () => import('./modules/Console/Pages/Console/ConsolePageModule').then(_m => _m.ConsolePageModule) },
    { path: 'c/:0/:1/:2/:3/:4/:5/:6/:7/:8/:9', loadChildren: () => import('./modules/Console/Pages/Console/ConsolePageModule').then(_m => _m.ConsolePageModule) },
];
let AppModuleRouterLazy = class AppModuleRouterLazy {
};
AppModuleRouterLazy = __decorate([
    NgModule({
        imports: [
            RouterModule.forRoot([
                { path: '', redirectTo: 'c', pathMatch: 'full' },
                // { path: 'log', loadChildren: () =>
                //   import('./modules/Log/Pages/Log/LogPageModule').then(_m => _m.LogPageModule) },
                // { path: 'changelog', loadChildren: () =>
                //   import('./modules/Changelog/Pages/Changelog/ChangelogPageModule').then(_m => _m.ChangelogPageModule) },
                // { path: 'changelist', loadChildren: () =>
                //   import('./modules/Changelog/Pages/Changelist/ChangelistPageModule').then(_m => _m.ChangelistPageModule) },
                ...pPositionRoute,
            ]),
        ],
        exports: [
            RouterModule,
        ],
    })
], AppModuleRouterLazy);
export { AppModuleRouterLazy };
