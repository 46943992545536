/**
 * @module global/Cache
 */
/**
 *
 */
import { CacheClient } from '@global/Cache/CacheClient';
import { delay } from '@global/Common/delay';
import { SerialKey } from '@global/KeyValue/SerialKey';
export class CacheMemClient extends CacheClient {
    constructor() {
        super();
        this._values = {};
        // TODO: Использовать сортированный массив.
        this._timeouts = [];
        (async () => {
            while (true) {
                // TODO: Использовать сортированный массив и не проверять массив до конца.
                const iTime = (new Date()).getTime();
                let i = 0;
                // console.log(this._timeouts.length);
                while (i < this._timeouts.length) {
                    const [sKey, iTimeout] = this._timeouts[i];
                    if (iTime > iTimeout) {
                        console.log(`Удаляем ${[sKey, iTimeout]}.`);
                        this._timeouts = this._timeouts.slice(0, i).concat(this._timeouts.slice(i + 1));
                        this._remove(sKey);
                    }
                    i++;
                }
                await delay(5000);
            }
        })();
    }
    getSync(_pKey) {
        const key = new SerialKey(_pKey);
        return this._get(key.Value);
    }
    async get(_pKey) {
        return this.getSync(_pKey);
    }
    /**
     *
     * @param _sKey
     * @return Возвращает значение или `null`
     */
    _get(_sKey) {
        return this._values[_sKey] || null;
    }
    setSync(_pKey, _value, _duration) {
        const key = new SerialKey(_pKey);
        return this._set(key.Value, _value, _duration);
    }
    async set(_pKey, _value, _duration) {
        return this.setSync(_pKey, _value, _duration);
    }
    // Object
    // inline
    _set(_sKey, _value, _duration) {
        this._values[_sKey] = _value;
        if (_duration !== undefined) {
            /// Таймоут через `_duration` относительно текущего времени.
            // this._timeouts[_key.Value] = 
            const iTimeoutNew = (new Date()).getTime() + _duration;
            this._timeouts.push([_sKey, iTimeoutNew]);
            // TODO: Найти нормальный алгоритм вставки значения в сортированный массив, чтобы не проверять весь массив полностью каждый раз.
        }
    }
    removeSync(_pKey) {
        const key = new SerialKey(_pKey);
        return this._remove(key.Value);
    }
    async remove(_pKey) {
        return this.removeSync(_pKey);
    }
    // Object
    // inline
    _remove(_sKey) {
        delete this._values[_sKey];
        // console.log(`Удаление ${ _sKey }`);
        // TODO: В `this._timeouts` может остаться мусор.
    }
}
