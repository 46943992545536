/** Дескриптор для замены async/await функций на промисы, чтобы срабатывали биндинги.
 * В Angular zone.js переписывает Promise, чтобы работали биндинги,
 * ф реализация async/await зависит от другой формы Promise.
 * Источник:
 * @param _target
 * @param _method
 * @param _descriptor
 */
export function ZoneAsync(_target, _method, _descriptor) {
    const originalMethod = _target[_method];
    _descriptor.value = function (..._pArg) {
        return Promise.resolve(originalMethod.apply(this, _pArg));
    };
}
