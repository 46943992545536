/**
 * @module client/Currency
 */
/**
 *
 */
import _ from 'lodash-es';
import { CURRENCIES } from './CurrencyCommon';
/** Базовый класс, он не знает откуда берутся курсы валют,
 * его нужно переопределять и переопределять метод `rate`
 */
export class CurrencyService {
    /** Курс валюты на дату по имени валюты
     * @param _dt Дата
     * @return Карта массивов [Курс, Номинал]
     */
    async _rate(_dt) {
        throw 'Функция CurrencyService._rate не определена, она должна быть определена в наследованных классах';
    }
    /** Запрос курса валюты
     * @param _dt Дата
     */
    async rate(_dt) {
        const mpfRate = await this._rate(_dt);
        const mfRate = _.mapValues(mpfRate, ([fValue, iNominal]) => {
            let fRate = fValue / iNominal;
            fRate = Math.round(fRate * 10000) / 10000;
            return fRate;
        });
        return mfRate;
    }
    /** Конвертирование суммы из одной валюты в другую.
     * @param _fAmount Сумма
     * @param _sCurrencyFrom Исходная валюта
     * @param _sCurrencyTo Результирующая валюта
     * @param _dt Дата
     */
    async convert(_fAmount, _sCurrencyFrom, _sCurrencyTo, _dt) {
        const psCurrency = [_sCurrencyFrom.toUpperCase(), _sCurrencyTo.toUpperCase()];
        for (const sCurrency of psCurrency) {
            if (CURRENCIES[sCurrency] === undefined) {
                throw `CurrencyService.convert. Неизвестная валюта "${sCurrency}"`;
            }
        }
        const mfRate = await this.rate(_dt);
        const fRateFrom = mfRate[psCurrency[0]];
        const fRateTo = mfRate[psCurrency[1]];
        return _fAmount * fRateFrom / fRateTo;
    }
    /** Округляет суммы возвращённые из this.convert до `decimal_digits знаков после запятой итоговой валюты.
     * @param _fAmount Сумма
     * @param _sCurrencyFrom Исходная валюта
     * @param _sCurrencyTo Результирующая валюта
     */
    async convertRound(_fAmount, _sCurrencyFrom, _sCurrencyTo, _dt) {
        const value = await this.convert(_fAmount, _sCurrencyFrom, _sCurrencyTo, _dt);
        const currencyTo = CURRENCIES[_sCurrencyTo.toUpperCase()];
        return Math.round(value * Math.pow(10, currencyTo.DecimalDigits)) /
            Math.pow(10, currencyTo.DecimalDigits);
    }
}
