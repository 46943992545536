var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RBToastsComponentModule } from '@client/RuiBootstrap/Components/Toasts/RBToastsComponentModule';
import { CredentialModule } from '../modules/Credentials/CredentialModule';
import { SupplierIndexComponentModule } from '../modules/Supplier/Components/Catalog/SupplierIndexComponentModule';
import { CbrDateComponentModule } from '../modules/CbrDate/Components/CbrDate/CbrDateComponentModule';
import { AppComponent } from './AppComponent';
let AppComponentModule = class AppComponentModule {
};
AppComponentModule = __decorate([
    NgModule({
        imports: [
            CommonModule,
            RouterModule,
            SupplierIndexComponentModule,
            // ProductCountComponentModule,
            // IpAddressComponentModule,
            CredentialModule,
            CbrDateComponentModule,
            RBToastsComponentModule,
        ],
        declarations: [
            AppComponent,
        ],
        exports: [
            AppComponent,
        ],
    })
], AppComponentModule);
export { AppComponentModule };
