/** Меняет функцию на кэшированную с обязательным превращением в асинхронную,
 * т.к. `apply` возвращает `Promise`.
 * @param _cache Клиент кэширования
 */
export function CacheHook(_cache) {
    return function Cache(_iPeriod) {
        return function (_target, _sPropName, _descriptor) {
            const originalMethod = _descriptor.value;
            const pMarkTemp = [_target.constructor.name, _sPropName];
            _descriptor.value = function (..._pArg) {
                const pMark = pMarkTemp.concat(_pArg);
                // console.log(pMark.join('/'));
                // return originalMethod.apply(this, _pArg);
                return _cache.resolve(pMark, () => originalMethod.apply(this, _pArg), _iPeriod);
                // let result = await _cache.get(pMark);
                // return new Promise((_resolve, _reject) => {
                //   if (result !== null) {
                //     _resolve(result);
                //   }
                //   originalMethod.apply(this, _pArg)
                //     .then(async _result => {
                //       await _cache.set(pMark, _result, _iPeriod);
                //       _resolve(_result);
                //     })
                //     .catch(_err => _reject(_err));
                // });
            };
        };
    };
}
// export function CacheHookAsync(_cache: ICacheClient) {
//   return function Cache(_iPeriod: number): Function {
//     return function(_target: Constructor<any>, _sPropName: string, _descriptor: PropertyDescriptor) {
//       const originalMethod: Function = _descriptor.value;
//       const pMarkTemp: Array<any> = [_target.constructor.name, _sPropName];
//       _descriptor.value = async function (... _pArg: Array<any>) {
//         const pMark: Array<any> = pMarkTemp.concat(_pArg);
//         console.log(pMark.join('/'));
//         return _cache.resolve(pMark, () => new Promise((_resolve, _reject) => {
//           originalMethod.apply(this, _pArg)
//             .then(_response => _resolve(_response))
//             .catch(_err => _reject(_err));
//         }), _iPeriod);
//       };
//     };
//   };
// }
