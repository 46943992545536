export function currencyConvert(_fAmount, _fRateFrom, _fRateTo, _sCurrencyTo) {
    const currencyTo = CURRENCIES[_sCurrencyTo.toUpperCase()];
    return Math.round((_fAmount * _fRateFrom / _fRateTo) * Math.pow(10, currencyTo.DecimalDigits)) /
        Math.pow(10, currencyTo.DecimalDigits);
}
export const CURRENCIES_POPULAR = ['USD', 'EUR', 'GBP', 'JPY', 'CNY'];
export const CURRENCIES = {
    'USD': { Symbol: '$', Name: 'US Dollar', SymbolNative: '$', DecimalDigits: 2, Code: 'USD', NamePlural: 'US dollars', Number: '840', Html: '&#36;' /* &dollar; */ },
    'CAD': { Symbol: 'CA$', Name: 'Canadian Dollar', SymbolNative: '$', DecimalDigits: 2, Code: 'CAD', NamePlural: 'Canadian dollars', Number: '124' },
    'EUR': { Symbol: '€', Name: 'Euro', SymbolNative: '€', DecimalDigits: 2, Code: 'EUR', NamePlural: 'euros', Number: '978', Html: '&#8364;' /* &euro; */ },
    'AED': { Symbol: 'AED', Name: 'United Arab Emirates Dirham', SymbolNative: 'د.إ.‏', DecimalDigits: 2, Code: 'AED', NamePlural: 'UAE dirhams', Number: '784' },
    'AFN': { Symbol: 'Af', Name: 'Afghan Afghani', SymbolNative: '؋', DecimalDigits: 0, Code: 'AFN', NamePlural: 'Afghan Afghanis', Number: '971' },
    'ALL': { Symbol: 'ALL', Name: 'Albanian Lek', SymbolNative: 'Lek', DecimalDigits: 0, Code: 'ALL', NamePlural: 'Albanian lekë', Number: '008' },
    'AMD': { Symbol: 'AMD', Name: 'Armenian Dram', SymbolNative: 'դր.', DecimalDigits: 0, Code: 'AMD', NamePlural: 'Armenian drams', Number: '051' },
    'ARS': { Symbol: 'AR$', Name: 'Argentine Peso', SymbolNative: '$', DecimalDigits: 2, Code: 'ARS', NamePlural: 'Argentine pesos', Number: '032' },
    'AUD': { Symbol: 'AU$', Name: 'Australian Dollar', SymbolNative: '$', DecimalDigits: 2, Code: 'AUD', NamePlural: 'Australian dollars', Number: '036' },
    'AZN': { Symbol: 'man.', Name: 'Azerbaijani Manat', SymbolNative: 'ман.', DecimalDigits: 2, Code: 'AZN', NamePlural: 'Azerbaijani manats', Number: '944' },
    'BAM': { Symbol: 'KM', Name: 'Bosnia-Herzegovina Convertible Mark', SymbolNative: 'KM', DecimalDigits: 2, Code: 'BAM', NamePlural: 'Bosnia-Herzegovina convertible marks', Number: '977' },
    'BDT': { Symbol: 'Tk', Name: 'Bangladeshi Taka', SymbolNative: '৳', DecimalDigits: 2, Code: 'BDT', NamePlural: 'Bangladeshi takas', Number: '050' },
    'BGN': { Symbol: 'BGN', Name: 'Bulgarian Lev', SymbolNative: 'лв.', DecimalDigits: 2, Code: 'BGN', NamePlural: 'Bulgarian leva', Number: '975' },
    'BHD': { Symbol: 'BD', Name: 'Bahraini Dinar', SymbolNative: 'د.ب.‏', DecimalDigits: 3, Code: 'BHD', NamePlural: 'Bahraini dinars', Number: '048' },
    'BIF': { Symbol: 'FBu', Name: 'Burundian Franc', SymbolNative: 'FBu', DecimalDigits: 0, Code: 'BIF', NamePlural: 'Burundian francs', Number: '108' },
    'BND': { Symbol: 'BN$', Name: 'Brunei Dollar', SymbolNative: '$', DecimalDigits: 2, Code: 'BND', NamePlural: 'Brunei dollars', Number: '096' },
    'BOB': { Symbol: 'Bs', Name: 'Bolivian Boliviano', SymbolNative: 'Bs', DecimalDigits: 2, Code: 'BOB', NamePlural: 'Bolivian bolivianos', Number: '068' },
    'BRL': { Symbol: 'R$', Name: 'Brazilian Real', SymbolNative: 'R$', DecimalDigits: 2, Code: 'BRL', NamePlural: 'Brazilian reals', Number: '986' },
    'BWP': { Symbol: 'BWP', Name: 'Botswanan Pula', SymbolNative: 'P', DecimalDigits: 2, Code: 'BWP', NamePlural: 'Botswanan pulas', Number: '072' },
    'BYR': { Symbol: 'BYR', Name: 'Belarusian Ruble', SymbolNative: 'BYR', DecimalDigits: 0, Code: 'BYR', NamePlural: 'Belarusian rubles', Number: '974' },
    'BZD': { Symbol: 'BZ$', Name: 'Belize Dollar', SymbolNative: '$', DecimalDigits: 2, Code: 'BZD', NamePlural: 'Belize dollars', Number: '084' },
    'CDF': { Symbol: 'CDF', Name: 'Congolese Franc', SymbolNative: 'FrCD', DecimalDigits: 2, Code: 'CDF', NamePlural: 'Congolese francs', Number: '976' },
    'CHF': { Symbol: 'CHF', Name: 'Swiss Franc', SymbolNative: 'CHF', DecimalDigits: 2, Code: 'CHF', NamePlural: 'Swiss francs', Number: '756' },
    'CLP': { Symbol: 'CL$', Name: 'Chilean Peso', SymbolNative: '$', DecimalDigits: 0, Code: 'CLP', NamePlural: 'Chilean pesos', Number: '152' },
    'CNY': { Symbol: 'CN¥', Name: 'Chinese Yuan', SymbolNative: 'CN¥', DecimalDigits: 2, Code: 'CNY', NamePlural: 'Chinese yuan', Number: '156', Html: '&#20803;' },
    'COP': { Symbol: 'CO$', Name: 'Colombian Peso', SymbolNative: '$', DecimalDigits: 0, Code: 'COP', NamePlural: 'Colombian pesos', Number: '170' },
    'CRC': { Symbol: '₡', Name: 'Costa Rican Colón', SymbolNative: '₡', DecimalDigits: 0, Code: 'CRC', NamePlural: 'Costa Rican colóns', Number: '188' },
    'CVE': { Symbol: 'CV$', Name: 'Cape Verdean Escudo', SymbolNative: 'CV$', DecimalDigits: 2, Code: 'CVE', NamePlural: 'Cape Verdean escudos', Number: '132' },
    'CZK': { Symbol: 'Kč', Name: 'Czech Republic Koruna', SymbolNative: 'Kč', DecimalDigits: 2, Code: 'CZK', NamePlural: 'Czech Republic korunas', Number: '203' },
    'DJF': { Symbol: 'Fdj', Name: 'Djiboutian Franc', SymbolNative: 'Fdj', DecimalDigits: 0, Code: 'DJF', NamePlural: 'Djiboutian francs', Number: '262' },
    'DKK': { Symbol: 'Dkr', Name: 'Danish Krone', SymbolNative: 'kr', DecimalDigits: 2, Code: 'DKK', NamePlural: 'Danish kroner', Number: '208' },
    'DOP': { Symbol: 'RD$', Name: 'Dominican Peso', SymbolNative: 'RD$', DecimalDigits: 2, Code: 'DOP', NamePlural: 'Dominican pesos', Number: '214' },
    'DZD': { Symbol: 'DA', Name: 'Algerian Dinar', SymbolNative: 'د.ج.‏', DecimalDigits: 2, Code: 'DZD', NamePlural: 'Algerian dinars', Number: '012' },
    'EGP': { Symbol: 'EGP', Name: 'Egyptian Pound', SymbolNative: 'ج.م.‏', DecimalDigits: 2, Code: 'EGP', NamePlural: 'Egyptian pounds', Number: '818' },
    'ERN': { Symbol: 'Nfk', Name: 'Eritrean Nakfa', SymbolNative: 'Nfk', DecimalDigits: 2, Code: 'ERN', NamePlural: 'Eritrean nakfas', Number: '232' },
    'ETB': { Symbol: 'Br', Name: 'Ethiopian Birr', SymbolNative: 'Br', DecimalDigits: 2, Code: 'ETB', NamePlural: 'Ethiopian birrs', Number: '230' },
    'GBP': { Symbol: '£', Name: 'British Pound Sterling', SymbolNative: '£', DecimalDigits: 2, Code: 'GBP', NamePlural: 'British pounds sterling', Number: '826', Html: '&#163;' /* &pound; */ },
    'GEL': { Symbol: 'GEL', Name: 'Georgian Lari', SymbolNative: 'GEL', DecimalDigits: 2, Code: 'GEL', NamePlural: 'Georgian laris', Number: '981' },
    'GHS': { Symbol: 'GH₵', Name: 'Ghanaian Cedi', SymbolNative: 'GH₵', DecimalDigits: 2, Code: 'GHS', NamePlural: 'Ghanaian cedis', Number: '936' },
    'GNF': { Symbol: 'FG', Name: 'Guinean Franc', SymbolNative: 'FG', DecimalDigits: 0, Code: 'GNF', NamePlural: 'Guinean francs', Number: '324' },
    'GTQ': { Symbol: 'GTQ', Name: 'Guatemalan Quetzal', SymbolNative: 'Q', DecimalDigits: 2, Code: 'GTQ', NamePlural: 'Guatemalan quetzals', Number: '320' },
    'HKD': { Symbol: 'HK$', Name: 'Hong Kong Dollar', SymbolNative: '$', DecimalDigits: 2, Code: 'HKD', NamePlural: 'Hong Kong dollars', Number: '344' },
    'HNL': { Symbol: 'HNL', Name: 'Honduran Lempira', SymbolNative: 'L', DecimalDigits: 2, Code: 'HNL', NamePlural: 'Honduran lempiras', Number: '340' },
    'HRK': { Symbol: 'kn', Name: 'Croatian Kuna', SymbolNative: 'kn', DecimalDigits: 2, Code: 'HRK', NamePlural: 'Croatian kunas', Number: '191' },
    'HUF': { Symbol: 'Ft', Name: 'Hungarian Forint', SymbolNative: 'Ft', DecimalDigits: 0, Code: 'HUF', NamePlural: 'Hungarian forints', Number: '348' },
    'IDR': { Symbol: 'Rp', Name: 'Indonesian Rupiah', SymbolNative: 'Rp', DecimalDigits: 0, Code: 'IDR', NamePlural: 'Indonesian rupiahs', Number: '360' },
    'ILS': { Symbol: '₪', Name: 'Israeli New Sheqel', SymbolNative: '₪', DecimalDigits: 2, Code: 'ILS', NamePlural: 'Israeli new sheqels', Number: '376' },
    'INR': { Symbol: 'Rs', Name: 'Indian Rupee', SymbolNative: 'টকা', DecimalDigits: 2, Code: 'INR', NamePlural: 'Indian rupees', Number: '356' },
    'IQD': { Symbol: 'IQD', Name: 'Iraqi Dinar', SymbolNative: 'د.ع.‏', DecimalDigits: 0, Code: 'IQD', NamePlural: 'Iraqi dinars', Number: '368' },
    'IRR': { Symbol: 'IRR', Name: 'Iranian Rial', SymbolNative: '﷼', DecimalDigits: 0, Code: 'IRR', NamePlural: 'Iranian rials', Number: '364' },
    'ISK': { Symbol: 'Ikr', Name: 'Icelandic Króna', SymbolNative: 'kr', DecimalDigits: 0, Code: 'ISK', NamePlural: 'Icelandic krónur', Number: '352' },
    'JMD': { Symbol: 'J$', Name: 'Jamaican Dollar', SymbolNative: '$', DecimalDigits: 2, Code: 'JMD', NamePlural: 'Jamaican dollars', Number: '388' },
    'JOD': { Symbol: 'JD', Name: 'Jordanian Dinar', SymbolNative: 'د.أ.‏', DecimalDigits: 3, Code: 'JOD', NamePlural: 'Jordanian dinars', Number: '400' },
    'JPY': { Symbol: '¥', Name: 'Japanese Yen', SymbolNative: '￥', DecimalDigits: 0, Code: 'JPY', NamePlural: 'Japanese yen', Number: '392', Html: '&#165;' /* &yen; */ },
    'KES': { Symbol: 'Ksh', Name: 'Kenyan Shilling', SymbolNative: 'Ksh', DecimalDigits: 2, Code: 'KES', NamePlural: 'Kenyan shillings', Number: '404' },
    'KHR': { Symbol: 'KHR', Name: 'Cambodian Riel', SymbolNative: '៛', DecimalDigits: 2, Code: 'KHR', NamePlural: 'Cambodian riels', Number: '116' },
    'KMF': { Symbol: 'CF', Name: 'Comorian Franc', SymbolNative: 'FC', DecimalDigits: 0, Code: 'KMF', NamePlural: 'Comorian francs', Number: '174' },
    'KRW': { Symbol: '₩', Name: 'South Korean Won', SymbolNative: '₩', DecimalDigits: 0, Code: 'KRW', NamePlural: 'South Korean won', Number: '410' },
    'KWD': { Symbol: 'KD', Name: 'Kuwaiti Dinar', SymbolNative: 'د.ك.‏', DecimalDigits: 3, Code: 'KWD', NamePlural: 'Kuwaiti dinars', Number: '414' },
    'KZT': { Symbol: 'KZT', Name: 'Kazakhstani Tenge', SymbolNative: 'тңг.', DecimalDigits: 2, Code: 'KZT', NamePlural: 'Kazakhstani tenges', Number: '398' },
    'LBP': { Symbol: 'LB£', Name: 'Lebanese Pound', SymbolNative: 'ل.ل.‏', DecimalDigits: 0, Code: 'LBP', NamePlural: 'Lebanese pounds', Number: '422' },
    'LKR': { Symbol: 'SLRs', Name: 'Sri Lankan Rupee', SymbolNative: 'SL Re', DecimalDigits: 2, Code: 'LKR', NamePlural: 'Sri Lankan rupees', Number: '144' },
    'LTL': { Symbol: 'Lt', Name: 'Lithuanian Litas', SymbolNative: 'Lt', DecimalDigits: 2, Code: 'LTL', NamePlural: 'Lithuanian litai', Number: '440' },
    'LVL': { Symbol: 'Ls', Name: 'Latvian Lats', SymbolNative: 'Ls', DecimalDigits: 2, Code: 'LVL', NamePlural: 'Latvian lati', Number: '428' },
    'LYD': { Symbol: 'LD', Name: 'Libyan Dinar', SymbolNative: 'د.ل.‏', DecimalDigits: 3, Code: 'LYD', NamePlural: 'Libyan dinars', Number: '434' },
    'MAD': { Symbol: 'MAD', Name: 'Moroccan Dirham', SymbolNative: 'د.م.‏', DecimalDigits: 2, Code: 'MAD', NamePlural: 'Moroccan dirhams', Number: '504' },
    'MDL': { Symbol: 'MDL', Name: 'Moldovan Leu', SymbolNative: 'MDL', DecimalDigits: 2, Code: 'MDL', NamePlural: 'Moldovan lei', Number: '498' },
    'MGA': { Symbol: 'MGA', Name: 'Malagasy Ariary', SymbolNative: 'MGA', DecimalDigits: 0, Code: 'MGA', NamePlural: 'Malagasy Ariaries', Number: '969' },
    'MKD': { Symbol: 'MKD', Name: 'Macedonian Denar', SymbolNative: 'MKD', DecimalDigits: 2, Code: 'MKD', NamePlural: 'Macedonian denari', Number: '807' },
    'MMK': { Symbol: 'MMK', Name: 'Myanma Kyat', SymbolNative: 'K', DecimalDigits: 0, Code: 'MMK', NamePlural: 'Myanma kyats', Number: '104' },
    'MOP': { Symbol: 'MOP$', Name: 'Macanese Pataca', SymbolNative: 'MOP$', DecimalDigits: 2, Code: 'MOP', NamePlural: 'Macanese patacas', Number: '446' },
    'MUR': { Symbol: 'MURs', Name: 'Mauritian Rupee', SymbolNative: 'MURs', DecimalDigits: 0, Code: 'MUR', NamePlural: 'Mauritian rupees', Number: '480' },
    'MXN': { Symbol: 'MX$', Name: 'Mexican Peso', SymbolNative: '$', DecimalDigits: 2, Code: 'MXN', NamePlural: 'Mexican pesos', Number: '484' },
    'MYR': { Symbol: 'RM', Name: 'Malaysian Ringgit', SymbolNative: 'RM', DecimalDigits: 2, Code: 'MYR', NamePlural: 'Malaysian ringgits', Number: '458' },
    'MZN': { Symbol: 'MTn', Name: 'Mozambican Metical', SymbolNative: 'MTn', DecimalDigits: 2, Code: 'MZN', NamePlural: 'Mozambican meticals', Number: '943' },
    'NAD': { Symbol: 'N$', Name: 'Namibian Dollar', SymbolNative: 'N$', DecimalDigits: 2, Code: 'NAD', NamePlural: 'Namibian dollars', Number: '516' },
    'NGN': { Symbol: '₦', Name: 'Nigerian Naira', SymbolNative: '₦', DecimalDigits: 2, Code: 'NGN', NamePlural: 'Nigerian nairas', Number: '566' },
    'NIO': { Symbol: 'C$', Name: 'Nicaraguan Córdoba', SymbolNative: 'C$', DecimalDigits: 2, Code: 'NIO', NamePlural: 'Nicaraguan córdobas', Number: '558' },
    'NOK': { Symbol: 'Nkr', Name: 'Norwegian Krone', SymbolNative: 'kr', DecimalDigits: 2, Code: 'NOK', NamePlural: 'Norwegian kroner', Number: '578' },
    'NPR': { Symbol: 'NPRs', Name: 'Nepalese Rupee', SymbolNative: 'नेरू', DecimalDigits: 2, Code: 'NPR', NamePlural: 'Nepalese rupees', Number: '524' },
    'NZD': { Symbol: 'NZ$', Name: 'New Zealand Dollar', SymbolNative: '$', DecimalDigits: 2, Code: 'NZD', NamePlural: 'New Zealand dollars', Number: '554' },
    'OMR': { Symbol: 'OMR', Name: 'Omani Rial', SymbolNative: 'ر.ع.‏', DecimalDigits: 3, Code: 'OMR', NamePlural: 'Omani rials', Number: '512' },
    'PAB': { Symbol: 'B/.', Name: 'Panamanian Balboa', SymbolNative: 'B/.', DecimalDigits: 2, Code: 'PAB', NamePlural: 'Panamanian balboas', Number: '590' },
    'PEN': { Symbol: 'S/.', Name: 'Peruvian Nuevo Sol', SymbolNative: 'S/.', DecimalDigits: 2, Code: 'PEN', NamePlural: 'Peruvian nuevos soles', Number: '604' },
    'PHP': { Symbol: '₱', Name: 'Philippine Peso', SymbolNative: '₱', DecimalDigits: 2, Code: 'PHP', NamePlural: 'Philippine pesos', Number: '608' },
    'PKR': { Symbol: 'PKRs', Name: 'Pakistani Rupee', SymbolNative: '₨', DecimalDigits: 0, Code: 'PKR', NamePlural: 'Pakistani rupees', Number: '586' },
    'PLN': { Symbol: 'zł', Name: 'Polish Zloty', SymbolNative: 'zł', DecimalDigits: 2, Code: 'PLN', NamePlural: 'Polish zlotys', Number: '985' },
    'PYG': { Symbol: '₲', Name: 'Paraguayan Guarani', SymbolNative: '₲', DecimalDigits: 0, Code: 'PYG', NamePlural: 'Paraguayan guaranis', Number: '600' },
    'QAR': { Symbol: 'QR', Name: 'Qatari Rial', SymbolNative: 'ر.ق.‏', DecimalDigits: 2, Code: 'QAR', NamePlural: 'Qatari rials', Number: '634' },
    'RON': { Symbol: 'RON', Name: 'Romanian Leu', SymbolNative: 'RON', DecimalDigits: 2, Code: 'RON', NamePlural: 'Romanian lei', Number: '946' },
    'RSD': { Symbol: 'din.', Name: 'Serbian Dinar', SymbolNative: 'дин.', DecimalDigits: 0, Code: 'RSD', NamePlural: 'Serbian dinars', Number: '941' },
    'RUB': { Symbol: 'RUB', Name: 'Russian Ruble', SymbolNative: 'руб.', DecimalDigits: 2, Code: 'RUB', NamePlural: 'Russian rubles', Number: '643', Html: '&#8381;' },
    'RWF': { Symbol: 'RWF', Name: 'Rwandan Franc', SymbolNative: 'FR', DecimalDigits: 0, Code: 'RWF', NamePlural: 'Rwandan francs', Number: '646' },
    'SAR': { Symbol: 'SR', Name: 'Saudi Riyal', SymbolNative: 'ر.س.‏', DecimalDigits: 2, Code: 'SAR', NamePlural: 'Saudi riyals', Number: '682' },
    'SDG': { Symbol: 'SDG', Name: 'Sudanese Pound', SymbolNative: 'SDG', DecimalDigits: 2, Code: 'SDG', NamePlural: 'Sudanese pounds', Number: '938' },
    'SEK': { Symbol: 'Skr', Name: 'Swedish Krona', SymbolNative: 'kr', DecimalDigits: 2, Code: 'SEK', NamePlural: 'Swedish kronor', Number: '752' },
    'SGD': { Symbol: 'S$', Name: 'Singapore Dollar', SymbolNative: '$', DecimalDigits: 2, Code: 'SGD', NamePlural: 'Singapore dollars', Number: '702' },
    'SOS': { Symbol: 'Ssh', Name: 'Somali Shilling', SymbolNative: 'Ssh', DecimalDigits: 0, Code: 'SOS', NamePlural: 'Somali shillings', Number: '706' },
    'SYP': { Symbol: 'SY£', Name: 'Syrian Pound', SymbolNative: 'ل.س.‏', DecimalDigits: 0, Code: 'SYP', NamePlural: 'Syrian pounds', Number: '760' },
    'THB': { Symbol: '฿', Name: 'Thai Baht', SymbolNative: '฿', DecimalDigits: 2, Code: 'THB', NamePlural: 'Thai baht', Number: '764' },
    'TND': { Symbol: 'DT', Name: 'Tunisian Dinar', SymbolNative: 'د.ت.‏', DecimalDigits: 3, Code: 'TND', NamePlural: 'Tunisian dinars', Number: '788' },
    'TOP': { Symbol: 'T$', Name: 'Tongan Paʻanga', SymbolNative: 'T$', DecimalDigits: 2, Code: 'TOP', NamePlural: 'Tongan paʻanga', Number: '776' },
    'TRY': { Symbol: 'TL', Name: 'Turkish Lira', SymbolNative: 'TL', DecimalDigits: 2, Code: 'TRY', NamePlural: 'Turkish Lira', Number: '949' },
    'TTD': { Symbol: 'TT$', Name: 'Trinidad and Tobago Dollar', SymbolNative: '$', DecimalDigits: 2, Code: 'TTD', NamePlural: 'Trinidad and Tobago dollars', Number: '780' },
    'TWD': { Symbol: 'NT$', Name: 'New Taiwan Dollar', SymbolNative: 'NT$', DecimalDigits: 2, Code: 'TWD', NamePlural: 'New Taiwan dollars', Number: '901' },
    'TZS': { Symbol: 'TSh', Name: 'Tanzanian Shilling', SymbolNative: 'TSh', DecimalDigits: 0, Code: 'TZS', NamePlural: 'Tanzanian shillings', Number: '834' },
    'UAH': { Symbol: '₴', Name: 'Ukrainian Hryvnia', SymbolNative: '₴', DecimalDigits: 2, Code: 'UAH', NamePlural: 'Ukrainian hryvnias', Number: '980' },
    'UGX': { Symbol: 'USh', Name: 'Ugandan Shilling', SymbolNative: 'USh', DecimalDigits: 0, Code: 'UGX', NamePlural: 'Ugandan shillings', Number: '800' },
    'UYU': { Symbol: '$U', Name: 'Uruguayan Peso', SymbolNative: '$', DecimalDigits: 2, Code: 'UYU', NamePlural: 'Uruguayan pesos', Number: '858' },
    'UZS': { Symbol: 'UZS', Name: 'Uzbekistan Som', SymbolNative: 'UZS', DecimalDigits: 0, Code: 'UZS', NamePlural: 'Uzbekistan som', Number: '860' },
    'VEF': { Symbol: 'Bs.F.', Name: 'Venezuelan Bolívar', SymbolNative: 'Bs.F.', DecimalDigits: 2, Code: 'VEF', NamePlural: 'Venezuelan bolívars', Number: '937' },
    'VND': { Symbol: '₫', Name: 'Vietnamese Dong', SymbolNative: '₫', DecimalDigits: 0, Code: 'VND', NamePlural: 'Vietnamese dong', Number: '704' },
    'XAF': { Symbol: 'FCFA', Name: 'CFA Franc BEAC', SymbolNative: 'FCFA', DecimalDigits: 0, Code: 'XAF', NamePlural: 'CFA francs BEAC', Number: '950' },
    'XOF': { Symbol: 'CFA', Name: 'CFA Franc BCEAO', SymbolNative: 'CFA', DecimalDigits: 0, Code: 'XOF', NamePlural: 'CFA francs BCEAO', Number: '952' },
    'YER': { Symbol: 'YR', Name: 'Yemeni Rial', SymbolNative: 'ر.ي.‏', DecimalDigits: 0, Code: 'YER', NamePlural: 'Yemeni rials', Number: '886' },
    'ZAR': { Symbol: 'R', Name: 'South African Rand', SymbolNative: 'R', DecimalDigits: 2, Code: 'ZAR', NamePlural: 'South African rand', Number: '710' }
};
