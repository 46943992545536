var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import __NG_CLI_RESOURCE__0 from "./SupplierIndexComponent.html";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import * as _ from 'lodash-es';
import { ValueComponent } from '@client/Value/ValueComponent';
import { delay, T } from '@global/Common/delay';
import { SupplierService } from '../../SupplierService';
import './SupplierIndexComponent.scss';
let SupplierIndexComponent = class SupplierIndexComponent extends ValueComponent {
    constructor(_cdr, _supplier) {
        super({});
        this._cdr = _cdr;
        this._supplier = _supplier;
        this._ = _;
        (async () => {
            while (true) {
                const m = await this._supplier.index();
                _.each(m, (_o, _sSupplier) => {
                    this.Value[_sSupplier] = { Brands: {}, Parts: {} };
                    _.each(_o.Brands, _sBrand => {
                        const sLetter = _sBrand.charAt(0);
                        if (this.Value[_sSupplier].Brands[sLetter] === undefined) {
                            this.Value[_sSupplier].Brands[sLetter] = [];
                        }
                        this.Value[_sSupplier].Brands[sLetter].push(_sBrand);
                    });
                    _.each(_o.Parts, _sPart => {
                        const sLetter = _sPart.charAt(0);
                        if (this.Value[_sSupplier].Parts[sLetter] === undefined) {
                            this.Value[_sSupplier].Parts[sLetter] = [];
                        }
                        this.Value[_sSupplier].Parts[sLetter].push(_sPart);
                    });
                });
                this._cdr.detectChanges();
                await delay(10 * T.SECOND); /// Запрашиваем каждые 10 сек.
            }
            // console.log(this.Value);
            // await delay(500);
        })();
    }
};
SupplierIndexComponent.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: SupplierService }
];
SupplierIndexComponent = __decorate([
    Component({
        changeDetection: ChangeDetectionStrategy.OnPush,
        selector: 'supplier-index',
        template: __NG_CLI_RESOURCE__0,
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef,
        SupplierService])
], SupplierIndexComponent);
export { SupplierIndexComponent };
