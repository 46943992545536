/**
 * @module global/KeyValue
 */
/**
 * Последнее изменение 19.06.2020
 * Последнее изменение 07.10.2020
 */
/// Разделитель строкового ключа.
export const SERIAL_DELIMITER = '/';
export class SerialKey {
    constructor(Parts) {
        this.Parts = Parts;
        // this.Value = JSON.stringify(this.Parts);
        this.Value = this.Parts.join(SERIAL_DELIMITER);
    }
}
