import _ from 'lodash-es';
/** Формирование даты для сайта РБК
 * @param _dt Дата
 * @return Массив чисел вида [Год, Месяц, День]
 */
export function cbrDate(_dt) {
    return [_dt.getFullYear(), _dt.getMonth() + 1, _dt.getDate()];
}
/** Формирование даты для сайта РБК
 * @param _dt Дата
 * @return Массив вида ['YYYY', 'MM', 'DD']
 */
export function cbrDateString(_dt) {
    const sT = '00';
    const [sY, sM, sD] = _.map(cbrDate(_dt), _i => _i.toString());
    return [sY, sT.substr(0, sT.length - sM.length) + sM, sT.substr(0, sT.length - sD.length) + sD];
}
/** Формирование строки запроса РБК
 * @param _sCurrencyNumber Номер валюты
 * @param _dt Дата
 * @return Адрес URL для запроса курса валюты
 */
export function cbrRbcUrl(_sCurrencyNumber, _dt) {
    const [sY, sM, sD] = cbrDateString(_dt);
    const sUrl = ['http://cbrates.rbc.ru/tsv', _sCurrencyNumber, sY, sM, `${sD}.tsv`].join('/');
    return sUrl;
}
/** Формирование строки запроса РБК
 * @param _dt Дата
 * @return Адрес URL для запроса курса валюты
 */
export function cbrXmlUrl(_dt) {
    const [sY, sM, sD] = cbrDateString(_dt);
    return `https://www.cbr.ru/scripts/XML_daily.asp?date_req=${[sD, sM, sY].join('/')}`;
}
/** Формирование строки запроса к 0k4
 * @param _dt Дата
 * @return Адрес URL для запроса курса валюты
 */
export function cbr0k4Url(_dt) {
    const [sY, sM, sD] = cbrDateString(_dt);
    return `http://cbr.0k4.ru/${[sY, sM, sD].join('/')}`;
}
