const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const MONTH = WEEK * 4;
const YEAR = MONTH * 12;
export const T = { SECOND, MINUTE, HOUR, DAY, WEEK, MONTH, YEAR };
export async function delay(_iMs) {
    return new Promise(_resolve => {
        setTimeout(() => _resolve(), _iMs);
    });
}
