var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import __NG_CLI_RESOURCE__0 from "./CbrDateComponent.html";
import { Component, Input, ViewChild } from '@angular/core';
import { NgbCalendar, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { CbrDateService } from '@client/CbrDate/CbrDateService';
import { ZoneAsync } from '@client/Common/ZoneAsync';
import './CbrDateComponent.scss';
let CbrDateComponent = class CbrDateComponent {
    constructor(Calendar, Cbr) {
        this.Calendar = Calendar;
        this.Cbr = Cbr;
    }
    ngOnInit() {
        this.Date = this.Calendar.getToday();
    }
    ngAfterViewInit() {
        this.DateInput.dateSelect.subscribe(async (_dt) => {
            const dt = new Date(_dt.year, _dt.month - 1, _dt.day);
            await this.onDate(dt);
        });
        (async () => {
            await this.onDate(new Date());
        })();
    }
    async onDate(_dt) {
        this.Cbr.change(_dt);
    }
};
CbrDateComponent.ctorParameters = () => [
    { type: NgbCalendar },
    { type: CbrDateService }
];
CbrDateComponent.propDecorators = {
    Date: [{ type: Input }],
    DateInput: [{ type: ViewChild, args: ['DateInput',] }]
};
__decorate([
    ZoneAsync,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Date]),
    __metadata("design:returntype", Promise)
], CbrDateComponent.prototype, "onDate", null);
CbrDateComponent = __decorate([
    Component({
        // changeDetection: ChangeDetectionStrategy.OnPush,
        selector: 'cbr-date',
        template: __NG_CLI_RESOURCE__0,
    }),
    __metadata("design:paramtypes", [NgbCalendar,
        CbrDateService])
], CbrDateComponent);
export { CbrDateComponent };
